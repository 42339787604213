import React from 'react';
import PropTypes from 'prop-types';
import kebabcase from 'lodash.kebabcase';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { randomColor } from 'randomcolor';
import SEO from '../components/seo';
import Layout from '../components/layout';
import theme from '../utils/theme';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        count: totalCount
      }
    }
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 860px;
  height: 100%;
  padding: 1.45rem 1.0875rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

function shuffle(array) {
  var m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

const TagsPage = props => {
  const tags = props.data.allMarkdownRemark.group.map((item, i) => {
    const color = randomColor({ luminosity: 'dark', format: 'rgbArray' }).join(
      ','
    );

    const size = 0.65 + item.count * 0.15;

    const tagCss = css`
      display: inline-block;
      margin: 0 0.5rem 0.5rem 0;
      padding: 0.2rem 0.4rem;
      font-size: ${size}rem;
      color: ${theme.dark.fgColor};
      background-color: rgb(${color});
      text-decoration: none;
      text-shadow: none;
      background-image: none;

      &:hover {
        background-color: ${theme.dark.bgColor};
        transform: scale(1.3);
        xcolor: ${theme.light.fgColor};
        xbackground-color: rgba(${color}, 0.7);
      }
    `;

    return (
      <Link
        key={i}
        css={tagCss}
        title={item.tag}
        to={`/tags/${kebabcase(item.tag)}/`}
      >
        {item.tag}&nbsp;({item.count})
      </Link>
    );
  });

  return (
    <Layout
      main={
        <Container>
          <SEO
            title="Tag list"
            keywords={['developer', 'development', 'tags', 'blog']}
          />
          <Title>Tags</Title>
          {shuffle(tags)}
        </Container>
      }
    />
  );
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          tag: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TagsPage;
